import orderLogic from 'public/src/pages/common/orderLogic'
import bankSelect from '../sub_modules/bank-select'
import { isObject, getQueryString } from '@shein/common-function'
import schttp from 'public/src/services/schttp'
import { getOrderStatusText } from 'public/src/pages/user/child_pages/orders/utils.js'

const setStatusLoading = (state, { v }) => {
  if (state.pageLoading) {
    return
  }
  state.status.loading = v
}

const state = {
  language: {},
  giftCard: {
    cards: {},
    orderCards: {}
  },
  orderSearchMsg: {
    show: false,
    content: '',
    isSearchList: false,
    page: 1
  },
  status_type: 0, // 订单分状态(1待付款订单, 2待发货, 3待收货, 4售后/退款, 5可评论)
  userOrderCounts: {},
  pageLoading: false,
  orderListNotice: '',
  relationAccount: false, // 是否存在邮箱手机号双账号关联
  orderStatusTextList: [],
  specialOutgoingOrderList: [],
  abnormalOrderList: [],
  abnormalOrderListType: 'old', // 暂时兼容旧字段，是否为旧回参类型
  shouldGiftCardShow: false,
  historyOrders: [],
  unionOrders: {},
  abtInfos: {
    customerItemSyncToUserAddressAbt: {},
    findOrderAbt: {},
    myPackageAbt: {},
    orderListAbtInfo: [],
    syncToUseraddressAbt: {},
    unpaidOrderTestAbt: {},
    abTrackTestConfig: {},
    abTestConfig: {}
  },
  apolloInfo: {},
  isDemotion: false, // 黑五降级
  orders: [],
  page: 1,
  reviewRewards: '',
  status: {
    loading: false,
    msg: false,
    msgLoading: false,
    historyExtra: false,
    histtoryStatus: false,
    historyShow: false,
    showHistory: false,
    showAddCart: false
  },
  noMoreData: false,
  extraInfo: {
    waitSendReturnOrder: []
  },
  isSsr: false,
  publicLocalData: {
    GB_cssRight: false,
    langPath: '',
    PUBLIC_CDN: '',
    SiteUID: '',
    WEB_CLIENT: '',
    lang: '',
    appLanguage: '',
    dateLangMap: {}
  }
}

const getters = {
  ordersStatus: state => {
    const { language } = state
    return {
      class: orderLogic.orderStatusColor(),
      text: orderLogic.orderStatus(language)
    }
  },
  giftCardOrdersStatus: state => {
    const { language } = state
    return {
      class: orderLogic.giftOrderStatusColor(),
      text: orderLogic.giftOrderStatus(language)
    }
  },
  returnStatusText: state => {
    const { language } = state
    return {
      0: language.SHEIN_KEY_PWA_15755,
      1: language.SHEIN_KEY_PWA_15242,
      2: language.SHEIN_KEY_PWA_15231,
      3: language.SHEIN_KEY_PWA_15214,
      4: language.SHEIN_KEY_PWA_15310,
    }
  }
}

const mutations = {
  assignOrderListState(state, payload = {}) {
    const { key, value } = payload
    if (!key && isObject(payload)) {
      Object.entries(payload).forEach(([item, itemValue]) => {
        if (isObject(state[item]) && isObject(itemValue)) {
          state[item] = {
            ...state[item],
            ...itemValue
          }
        } else {
          state[item] = itemValue
        }
      })
      return
    }
    if (isObject(state[key]) && isObject(value)) {
      state[key] = {
        ...state[key],
        ...value,
      }
    } else {
      state[key] = value
    }
  },
  assignLanguage (state, payload) {
    state.language = { ...state.language, ...payload }
  },
  updateGiftCard (state, payload) {
    const { cards, orderCards } = payload
    state.giftCard.cards = cards
    state.giftCard.orderCards = orderCards
  },
  setAbtInfoData(state, payload) {
    state.abtInfos.moreStyle = payload?.returnStyle?.MoreStyle || ''
    state.abtInfos.findOrderAbt = payload?.findOrderAbt
    state.abtInfos.customerItemSyncToUserAddressAbt = payload?.customerItemSyncToUserAddressAbt
    state.abtInfos.syncToUseraddressAbt = payload?.syncToUseraddressAbt

    if (payload?.orderListAbtInfo?.length) {
      state.abtInfos.orderListAbtInfo = payload.orderListAbtInfo
      state.abtInfos.uploadReportShow = payload.uploadReportShow
      state.abtInfos.isCodAddressEditEnable = payload.isCodAddressEditEnable
      state.abtInfos.isOpenRiskInfoByAbt = payload.isOpenRiskInfoByAbt
      // abt是否显示查看发票按钮
      // 如果是off，则关闭入口，isOpenViewInvoicesByAbt为false，其他情况都为true
      state.abtInfos.isOpenViewInvoicesByAbt = payload.isOpenViewInvoicesByAbt
    } else {
      state.abtInfos.orderListAbtInfo = ['address=on', 'pay=on', 'verify=on', 'paytime=on', 'returnitem=on']
      state.abtInfos.isCodAddressEditEnable = true
      state.abtInfos.isOpenViewInvoicesByAbt = true
    }

    // 如果是off，则不允许，其他情况都为true

    state.abtInfos.isInfoFrontAbt = payload?.isInfoFrontAbt
    state.abtInfos.isCanRefundAbt = payload?.isCanRefundAbt
    state.abtInfos.isOpenShopUrl = payload?.isOpenShopUrl
    state.abtInfos.isShowOrderResellEntrance = payload?.isShowOrderResellEntrance
    state.abtInfos.isShowUrgeshipmentAbt = payload?.isShowUrgeshipmentAbt
    state.abtInfos.isShowExchangeAbt = payload?.isShowExchangeAbt
    // abt 是否可以展示卡bin优惠信息
    state.abtInfos.isShowCardBinDiscountABT = payload?.isShowCardBinDiscountABT
    // abt 是否可以展示卡bin优惠随机立减
    state.abtInfos.isShowBinRandomDiscountABT = payload?.isShowBinRandomDiscountABT
    state.abtInfos.showCommentRewardAbt = payload?.showCommentRewardAbt
    state.abtInfos.isNewStoreIcon = payload?.isNewStoreIcon


    // 关联账号abt
    const accountAbt = payload?.accountAbt
    if (['related', 'free'].includes(accountAbt)) {
      state.abtInfos.relationAccountAbt = accountAbt
    }

    // 双账号关联按钮展示方式abt开关
    state.abtInfos.orderlistRelatedIconAbt = payload.orderlistRelatedIconAbt
    state.abtInfos.codRiskOrderAbt = payload?.codRiskOrderAbt
    state.abtInfos.batchModifyAddressAbt = payload?.batchModifyAddressAbt
    state.abtInfos.isShowUrgeDeliveryAbt = payload?.isShowUrgeDeliveryAbt
    state.abtInfos.timeCanDelivery = payload?.timeCanDelivery
    state.abtInfos.isReturnStatusFrontOn = payload?.isReturnStatusFrontOn
    state.abtInfos.showEditOrderAddress = payload?.showEditOrderAddress

    state.abtInfos.abTestConfig = payload.abTestConfig
    state.abtInfos.isReturnOff = payload.isReturnOff
    state.abtInfos.myPackageAbt = payload?.myPackageAbt
    state.abtInfos.unpaidOrderTestAbt = payload?.unpaidOrderTestAbt
    state.abtInfos.showPriceGuaranteeAbt = payload?.showPriceGuaranteeAbt
    state.abtInfos.isRejectedReasonOn = payload?.isRejectedReasonOn
    state.abtInfos.abTrackTestConfig = payload?.abTrackTestConfig
    state.abtInfos.showApplepayDropoffOptimization = payload?.showApplepayDropoffOptimization
    state.abtInfos.isShowReturnAndRefund = payload?.isShowReturnAndRefund
  },
  // 带状态订单
  updateByStatusOrderList (state, payload) {
    state.page = state.page + 1
    if (payload?.i18n) {
      state.language = { ...state.language, ...payload?.i18n }
    }
    if (payload?.code == '0' && payload?.info) {
      state.isDemotion = payload.info?.is_demotion == 777777

      let orders = payload?.info?.orders || []
      if (orders?.length < 10) {
        state.noMoreData = true
      }
      // 存在卡bin优惠金额时，应付金额应为不含卡bin优惠金额的金额
      orders = orders.map(order => {
        if (order?.card_bin_discount_amount?.amount > 0 && order?.no_bin_discount_pay_amount?.amount > 0) {
          order.totalPrice = order.no_bin_discount_pay_amount
        }
        return order
      })
      state.orders = [...state.orders, ...orders]

      if (state.pageLoading) {
        return
      }
      state.status.loading = false
    }
  },
  // 所有订单
  updateAllOrderList (state, payload) {
    const { isSearchList } = state.orderSearchMsg || {}
    if (isSearchList) {
      state.orderSearchMsg = {
        ...state.orderSearchMsg,
        page: state.orderSearchMsg.page + 1
      }
    } else {
      state.page = state.page + 1
    }
    if (payload?.code == '0' && payload?.info) {
      state.isDemotion = payload.info?.is_demotion == 777777

     
      let orders = payload.info.orders || []
      // 定义初始免费退变量
      orders.forEach(item => {
        if (typeof item.isFreeRefund !== 'boolean') {
          item.isFreeRefund = false
        }
      })
      if (payload?.i18n) {
        state.language = { ...state.language, ...payload?.i18n }
      }

      if (!orders?.length) {
        state.noMoreData = true
        state.status.histtoryStatus = true
      } else {
        state.orders = [...state.orders, ...orders]
      }
      setStatusLoading(state, { v: false })
    }
  },
  // 历史订单
  updateHistoryOrderList (state, payload) {
    const { data = {}, type } = payload || {}
    if (data.code == 0) {
      if (type === 'showHistory') {
        if (data.info.sum > 0) {
          state.status.showHistory = true
        }
        return
      }
      state.noMoreData = false

      if (data.info.orders.length == 0) {
        state.noMoreData = true
        state.status.historyExtra = true
      } else {
        state.orders = [...state.orders, ...data.info.orders]
      }
      setStatusLoading(state, { v: false })
    } else {
      setStatusLoading(state, { v: false })
    }
  },
  // 过滤某个订单
  filterDeleteOrderEvt(state) {
    const deletedBillNo = getQueryString({ key: 'deletedBillNo' }) || ''
    if (state.orders?.length && deletedBillNo) {
      const orders = state.orders.filter(item => item.billno !== deletedBillNo)
      state.orders = orders
    }
  },
}

async function getUnionOrderListPageData ({ orderSearchMsg, statusType } = {}) {
  const { isSearchList, page: sPage, content: sKeyword } = orderSearchMsg
  const unionData = await schttp({
    method: 'POST',
    url: '/api/orders/base/unionOrderListPageData/get',
    data: {
      // 订单列表 【状态】
      status_type: statusType,
      // 订单搜索
      isSearchList,
      sPage,
      sKeyword
    },
    schttp: { needLogin: true }
  })
  return unionData
}
const actions = {
  investData({ commit, state }, { unionData, orderStatusText }) {
    const { i18n, abtInfos, orders: unionOrders, isExistGiftCard, historyOrders, abnormalOrder, specialOutgoingOrder, relationAccount, orderListNotice, userOrderCounts, reviewRewards, apolloInfo, isSsr, locals } = unionData || {}
    const extraInfo = unionOrders?.info?.extraInfo || {}
       
    commit('assignLanguage', i18n)
    commit('assignOrderListState', {
      pageLoading: false,
      shouldGiftCardShow: isExistGiftCard,
      abnormalOrderListType: abnormalOrder.type,
      abnormalOrderList: abnormalOrder?.lists || [],
      specialOutgoingOrderList: specialOutgoingOrder || [],
      relationAccount,
      orderListNotice,
      userOrderCounts,
      orderStatusTextList: orderStatusText?.length ? orderStatusText : [],
      historyOrders,
      unionOrders,
      reviewRewards,
      extraInfo,
      apolloInfo,
      publicLocalData: locals,
      isSsr
    })
    commit('setAbtInfoData', abtInfos)
    if (state.status_type) {
      commit('updateByStatusOrderList', unionOrders)
    } else {
      commit('updateAllOrderList', unionOrders)
    }
    commit('updateHistoryOrderList', { data: historyOrders, type: 'showHistory' })
    commit('filterDeleteOrderEvt')
  },
  async initSsrData ({ dispatch, commit, state }, { context, route, statusType, from }) {
    const isSsrPage = !from?.name
    if (isSsrPage && context?.ssrPageType === 'ordersList') {
      dispatch('investData', { unionData: context, orderStatusText: [] })
      return
    }
    if (typeof window !== 'undefined') {
      commit('assignOrderListState', { pageLoading: true })
      let unionData = {}
      let orderStatusText = []
      const orderListPrefetch = route.meta.orderListPrefetch
      if (orderListPrefetch) {
        route.meta.orderListPrefetch = null
        const [prefetchUnionData, prefetchOrderStatusText] = await Promise.resolve(orderListPrefetch)
        unionData = prefetchUnionData || {}
        orderStatusText = prefetchOrderStatusText || []
      } else {
        const [unionPageData, orderStatusTextData] = await Promise.all([getUnionOrderListPageData({
          orderSearchMsg: state.orderSearchMsg,
          statusType
        }), getOrderStatusText()])
        unionData = unionPageData || {}
        orderStatusText = orderStatusTextData || []
      }
      dispatch('investData', { unionData, orderStatusText })
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
  modules: {
    bankSelect
  }
}
